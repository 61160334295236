<template>
  <ItemUpdate
    v-if="true"
    :uuid="uuid"
    @onsave="OnSave"
    @oncancel="OnCancel"
    cancel-text="Cancel"
  />
</template>

<script>
import ItemUpdate from "@/components/ui/item/ItemUpdate.vue";
import { useRouter } from "vue-router";
export default {
  components: { ItemUpdate },
  props: {
    uuid: {
      type: String,
    },
  },
  setup(props) {
    const OnSave = () => {
      router.replace({ name: "item-detail", params: { uuid: props.uuid } });
    };
    const router = useRouter();
    const OnCancel = () => {
      router.replace({ name: "item-detail", params: { uuid: props.uuid } });
    };
    return {
      OnSave,
      OnCancel,
    };
  },
};
</script>